import { Button, Col, message, Row, Tag, Typography } from 'antd'
import { collection, doc, getDocs, getFirestore, onSnapshot, query } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'reactfire'
import { PricingPlan } from '../../../interfaces/PricingPlanDef'
import { setBillingAccount } from '../../context/billingAccountSlice'
import { preserveQueryParams, SearchParamInURL } from '../../functions/helpers'
import ConfirmOnboardingStep from '../../new-onboarding/onboarding/steps/ConfirmOnboardingStep'
import { handleSignOut } from '../UI/Header'
import PricingCard from './PricingCard'
import PricingDeckSwitch from './PricingDeckSwitch'
import { usePostHog } from 'posthog-js/react'

const PLAN_ORDER = ['free', 'free-plus', 'essential', 'advanced', 'professional', 'professional-plus']
const FREE_PLANS = ['free', 'free-plus']

export default function PricingCardDeck() {
    const db = getFirestore()
    const auth = useAuth()
    const dispatch = useDispatch()
    const posthog = usePostHog()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const [plans, setPlans] = useState<PricingPlan[]>([])
    const [displayedPlans, setDisplayedPlans] = useState<PricingPlan[]>([])
    const [type, setType] = useState<'monthly' | 'annual'>('monthly')

    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const url = new URL(window.location.href)
    const params = useMemo(() => new URLSearchParams(url.search), [url.search])

    const showAll = searchParams.get('show_all') === 'true' ? true : false

    const sortedPlans = useMemo(
        () => [...plans].sort((a, b) => PLAN_ORDER.indexOf(a.id) - PLAN_ORDER.indexOf(b.id)),
        [plans],
    )

    const freePlan = useMemo(() => sortedPlans.find((plan) => plan.id === 'free'), [sortedPlans])

    const isDowngrade = (selectedPlanId: string) => {
        if (!billingAccount?.plan?.id) return false
        const currentPlanIndex = PLAN_ORDER.indexOf(billingAccount.plan.id)
        const selectedPlanIndex = PLAN_ORDER.indexOf(selectedPlanId)
        return selectedPlanIndex < currentPlanIndex
    }

    useEffect(() => {
        const getPlans = async () => {
            const q = query(collection(db, 'subscriptionPricing'))
            const subscriptionPlans = await getDocs(q)
            const fetchedPlans = subscriptionPlans.docs.map((doc) => doc.data()) as PricingPlan[]
            setPlans(fetchedPlans)
        }
        try {
            posthog.capture('onboarding_plan_section_viewed', {
                v: 'v2',
                billingAccount: billingAccount?.id,
                userId: auth.currentUser?.uid,
            })
        } catch (error) {}
        getPlans()
    }, [db, posthog, billingAccount, auth?.currentUser?.uid])

    useEffect(() => {
        if (!freePlan) return

        if (showAll) {
            // Si show_all es true, mostrar todos los planes pagos incluyendo professional-plus
            const finalPlans = sortedPlans.filter((plan) => !FREE_PLANS.includes(plan.id) && plan.id !== 'custom')
            setDisplayedPlans(finalPlans)
        } else {
            // Mantener el comportamiento original cuando show_all es false
            const filteredPlans = sortedPlans.filter(
                (plan) => !['custom', 'free-plus', 'professional-plus'].includes(plan.id),
            )

            setDisplayedPlans(filteredPlans)
        }
    }, [sortedPlans, freePlan, showAll])

    useEffect(() => {
        const subscriptionStatus = SearchParamInURL('subscription')
        if (!billingAccount.id && subscriptionStatus === 'success') {
            setIsLoading(true)
            return
        }

        if (subscriptionStatus === 'cancel') {
            return message.error('Cancelaste la transacción, no hemos realizado ningún cargo')
        }
        if (subscriptionStatus !== 'success') return

        setIsLoading(true)
        const billingRef = doc(db, 'billingAccounts', billingAccount?.id)

        const checkSubscription = () => {
            return new Promise(async (resolve, reject) => {
                await new Promise((resolve) => setTimeout(resolve, 2000))
                const unsubscribe = onSnapshot(billingRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        const billingData = docSnapshot.data()
                        const planId = billingData?.plan?.id
                        const subscriptionId = billingData?.plan?.subscription

                        if (planId && subscriptionId) {
                            unsubscribe()
                            resolve(billingData)
                        }
                    }
                })

                setTimeout(() => {
                    unsubscribe()
                    reject()
                }, 13000)
            })
        }

        const pollSubscription = async () => {
            try {
                const billingAccount = await checkSubscription()

                setIsSubscriptionActive(true)
                await new Promise((resolve) => setTimeout(resolve, 2500))

                dispatch(setBillingAccount(billingAccount))
                navigate(preserveQueryParams('/onboarding/team', params, ['subscription']))
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                message.error('No se pudo configurar tu plan, por favor intenta de nuevo')
            }
        }

        pollSubscription()
    }, [billingAccount, billingAccount?.id, db, dispatch, navigate, params])

    if (isLoading) {
        return <ConfirmOnboardingStep type="waiting-subscription" success={isSubscriptionActive} />
    }

    return (
        <div className="pricing-card-deck">
            {auth.currentUser && (
                <Button
                    className="btn-link"
                    onClick={async () => handleSignOut({ dispatch, auth })}
                    style={{
                        position: 'absolute',
                        top: '16px',
                        right: '16px',
                        color: '#666',
                    }}
                >
                    Cerrar sesión
                </Button>
            )}
            <Row style={{ justifyContent: 'center' }}>
                <Col style={{ justifyContent: 'center' }}>
                    <Tag className="bg-primary-2 primary-1 p-small-bold rounded-lg" style={{ borderWidth: '0' }}>
                        Comienza con gigstack
                    </Tag>
                </Col>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
                <Col style={{ justifyContent: 'center' }}>
                    <Typography className="h1-bold">Elige el mejor plan para ti</Typography>
                </Col>
            </Row>
            <Row style={{ justifyContent: 'center' }}>
                <Col>
                    <PricingDeckSwitch parentCallback={(t: any) => setType(t)} />
                </Col>
            </Row>
            <>
                <Row
                    gutter={[16, 16]}
                    className="pricing-cards"
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 300px))',
                        gridAutoRows: '1fr',
                        justifyContent: 'center',
                        gap: '16px',
                    }}
                >
                    {displayedPlans.map((plan, index) => (
                        <div key={plan.id + type} style={{ height: '100%' }}>
                            <PricingCard
                                plan={plan}
                                billingCycle={type}
                                isRecommended={showAll ? index === 1 : index === 2}
                                disabled={isDowngrade(plan.id)}
                                isDowngrade={isDowngrade(plan.id)}
                            />
                        </div>
                    ))}
                </Row>
            </>
        </div>
    )
}
