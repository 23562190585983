import { Col, Row, Typography } from 'antd'
import { useMissingOnboardingStepsCards, useNoticeCards } from '../../customHooks/useNoticeBoard'
import NoticeCard from './NoticeCard'
import { memo } from 'react'

// Define the props interface (even if empty)
interface NoticeBoardProps {
    // Add any props here if needed
}

const NoticeBoard = memo<NoticeBoardProps>(function MemoizedNoticeBoard(){
    const { noticeCards } = useNoticeCards()
    const { missingOnboardingSteps } = useMissingOnboardingStepsCards()

    if (noticeCards.length === 0 && missingOnboardingSteps.length === 0) {
        return null;
    }

    return (
        <Col xs={23} lg={23}>
            <div
                // Color and title are determined by whether onboarding steps are missing
                className={`rounded-md ${missingOnboardingSteps.length > 0 ? 'bg-neutral-8' : 'bg-white'}`}
                style={{
                    padding: '20px',
                }}
            >
                <Row justify="space-between" align="middle" wrap>
                    <div className="d-flex flex-column">
                        <div id="newFeats">
                            <Typography.Text className="h3-bold">
                                {missingOnboardingSteps.length > 0 ? '¡Te damos la bienvenida a gigstack!' : 'Avisos importantes'}
                            </Typography.Text>
                        </div>
                        {missingOnboardingSteps.length > 0 && (
                            <Typography.Text className="p-base-regular">
                                Termina de configurar tu experiencia con los siguientes pasos:
                            </Typography.Text>
                        )}
                    </div>
                </Row>
                <Row>
                    {/* // TODO substitute card with actual card component */}
                    {noticeCards.map((card) => <NoticeCard key={card.id} card={card} />)} 
                </Row>
            </div>
        </Col>
    )
})

export default NoticeBoard
