import { SelectInput } from '../../components/Forms/Inputs'
import { countryLabeledListWithCodeAlpha3 } from '../../datasets/Countries'

interface CountrySelectProps {
    name: string
    label: string
    placeholder: string
    rules?: any[]
}

const CountrySelect = ({ label, placeholder, rules }: CountrySelectProps) => {
    const SelectCountryRender = (option: any) => {
        const alpha2 = countryLabeledListWithCodeAlpha3.find((c) => c.code === option.value)?.alpha2?.toLowerCase()

        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <img
                    src={`https://hatscripts.github.io/circle-flags/flags/${alpha2}.svg`}
                    alt={`${option.label} flag`}
                    style={{ width: '24px', height: '18px' }}
                />
                <span>{option.label}</span>
            </div>
        )
    }

    return (
        <SelectInput
            placeholder={placeholder}
            options={countryLabeledListWithCodeAlpha3.map((c) => ({
                label: c.name,
                value: c.code,
            }))}
            label={label}
            optionRender={SelectCountryRender}
            labelRender={SelectCountryRender}
            name="country"
            rules={rules}
        />
    )
}

export default CountrySelect
