import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useAuth } from 'reactfire'
import { UserDef } from '../../../interfaces/userDef'
import Loader from '../../appStates/LoadingView'
import PricingCardDeck from '../../components/ComponentIndividual/PricingCardDeck'
import { PendingInvitesView } from '../../components/Invites/PendingInvites'
import { setBillingAccount } from '../../context/billingAccountSlice'
import { ONBOARDING_STEP, setInvites, setInvitesLoaded } from '../../context/onboardingSlice'
import { setTeam } from '../../context/teamsSlice'
import { setUser } from '../../context/userSlice'
import { preserveQueryParams } from '../../functions/helpers'
import { createBillingAccount, createTeam } from '../../new-onboarding/auth/utils'
import Onboarding from '../../new-onboarding/onboarding/Onboarding'
import ConfirmOnboardingStep from '../../new-onboarding/onboarding/steps/ConfirmOnboardingStep'
import { usePostHog } from 'posthog-js/react'

// Separate async functions for better organization and reusability
export const fetchInvites = async () => {
    try {
        const functions = getFunctions()
        const checkInvites = httpsCallable(functions, 'checkinvitesonboarding')
        await new Promise((resolve) => setTimeout(resolve, 1000))
        const result: any = await checkInvites()
        return result?.data?.invites || []
    } catch (error) {
        console.error('Error fetching invites:', error)
        return []
    }
}

const createInitialResources = async (auth: any, user: UserDef, dispatch: any, ref: any, posthog?: any) => {
    if (ref.current) return null
    ref.current = true

    if (!auth.currentUser?.uid) return null

    // Check if user already has resources in Firestore
    const userDoc = await getDoc(doc(getFirestore(), `users/${auth.currentUser.uid}`))
    if (!userDoc.exists()) return null

    try {
        posthog?.capture('first_time_user_setup', {
            v: 'v2',
            userId: auth?.currentUser?.uid,
        })
    } catch (error) {}

    const userData = userDoc.data() as UserDef
    if (userData.billingAccounts?.length > 0 || userData.teams?.length > 0) {
        return null // Resources already exist
    }

    const name = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim()

    // Create billing account
    const billingAccountData = {
        legalName: name,
        legal_name: name,
        email: auth.currentUser?.email ?? '',
    }

    const newBillingAccount = await createBillingAccount(billingAccountData, user, dispatch, auth)
    if (!newBillingAccount?.id) return null
    try {
        posthog?.capture('first_time_ba_setup', {
            v: 'v2',
            billingAccount: newBillingAccount?.id,
        })
    } catch (error) {}

    // Create team
    const teamData = { billingAccount: newBillingAccount.id }
    const newTeam = await createTeam(teamData, user, dispatch, auth)
    if (!newTeam?.id) return null

    try {
        posthog?.capture('first_time_team_setup', {
            v: 'v2',
            team: newTeam?.id,
        })
    } catch (error) {}

    // Update user document
    const userUpdates = {
        lastTeamViewed: newTeam.id,
        billingAccount: newBillingAccount.id,
        lastBillingAccountViewed: newBillingAccount.id,
        teams: [newTeam.id],
        billingAccounts: [newBillingAccount.id],
    }

    await updateDoc(doc(getFirestore(), 'users', auth.currentUser.uid), userUpdates)

    dispatch(setBillingAccount(newBillingAccount))
    dispatch(setTeam(newTeam))
    dispatch(
        setUser({
            ...user,
            ...userUpdates,
        }),
    )

    return { team: newTeam, billingAccount: newBillingAccount }
}

const OnboardingCheck = ({ children }: { children: JSX.Element }) => {
    const auth = useAuth()
    const posthog = usePostHog()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(true)

    const { user } = useSelector((state: any) => state.user)
    const { team } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const shouldCreateRef = useRef(false)

    const { invites, invitesLoaded, currentStep: forceStep } = useSelector((state: any) => state.onboarding)

    const url = new URL(window.location.href)
    const params = useMemo(() => new URLSearchParams(url.search), [url.search])

    const navigateToStep = (step: string) => {
        navigate(preserveQueryParams(`/onboarding/${step}`, params))
    }

    useEffect(() => {
        const initializeOnboarding = async () => {
            try {
                setIsLoading(true)

                // Check for basic user data
                if (!auth?.currentUser?.email || !auth.currentUser?.displayName) {
                    navigateToStep('user')
                    return
                }

                // Check for invites if not already loaded
                if (!invitesLoaded) {
                    const invitesResponse = await fetchInvites()
                    dispatch(setInvitesLoaded(true))

                    if (invitesResponse.length > 0) {
                        dispatch(setInvites(invitesResponse))
                        navigateToStep('invite')
                        return
                    }
                }

                // Create resources if needed
                if (!team?.id && !billingAccount?.id) {
                    await createInitialResources(auth, user, dispatch, shouldCreateRef, posthog)
                }

                // Determine current step based on completion status
                if (invites.length > 0) {
                    navigateToStep('invite')
                } else if (!billingAccount.plan?.id || forceStep === ONBOARDING_STEP.PLAN) {
                    navigateToStep('plan')
                } else if (!team.brand?.alias || !team.brand?.primaryColor || forceStep === ONBOARDING_STEP.TEAM) {
                    navigateToStep('team')
                } else if (
                    (!team.legal_name && !team.legalName) ||
                    !team.address.zip ||
                    !team.rfc ||
                    forceStep === ONBOARDING_STEP.FISCAL_DATA
                ) {
                    navigateToStep('fiscal-data')
                } else if (!team?.facturapi?.completed || forceStep === ONBOARDING_STEP.SAT_CONNECTION) {
                    navigateToStep('sat-connection')
                } else {
                    navigateToStep('finished')
                }
            } catch (error) {
                console.error('Error in onboarding initialization:', error)
            } finally {
                setIsLoading(false)
            }
        }

        initializeOnboarding()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team, billingAccount]) // Empty dependency array since we're using ref to control initialization

    if (isLoading) {
        return <Loader loading={true} />
    }

    return children
}

const OnboardingRoutes = () => {
    return (
        <OnboardingCheck>
            <Routes>
                <Route path="/onboarding/user" element={<Onboarding step={ONBOARDING_STEP.USER} />} />
                <Route path="/onboarding/invite" element={<PendingInvitesView generateNewTeamF={() => {}} />} />
                <Route path="/onboarding/plan" element={<PricingCardDeck />} />
                <Route path="/onboarding/team" element={<Onboarding step={ONBOARDING_STEP.TEAM} />} />
                <Route path="/onboarding/fiscal-data" element={<Onboarding step={ONBOARDING_STEP.FISCAL_DATA} />} />
                <Route
                    path="/onboarding/sat-connection"
                    element={<Onboarding step={ONBOARDING_STEP.SAT_CONNECTION} />}
                />
                <Route path="/onboarding/finished" element={<ConfirmOnboardingStep type="onboarding-finished" />} />
            </Routes>
        </OnboardingCheck>
    )
}

export default OnboardingRoutes
