import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { checkCsdExpiration } from '../../helpers/helperFunctions'
import { Stamp } from '@phosphor-icons/react/dist/ssr'

export interface NoticeCard {
    id: string
    title: string
    description: string
    icon: () => JSX.Element
    expirationDate?: number
}

// add missingOnboardingSteps to hook
export function useNoticeCards() {
    // We can add more notice cards here
    const cardCatalog = [
        {
            id: 'csdToExpire',
            title: 'Tus sellos (CSD) están por vencer',
            description: 'Tus sellos vencerán el . Para evitar problemas con tu facturación, actualízalos ahora',
            icon: () => <Stamp size={30} color="var(--primary-1)" />,
            expirationDate: 0,
        },
        {
            id: 'csdExpired',
            title: 'Tus sellos (CSD) están vencidos',
            description: 'Tus sellos vencieron el . Para evitar problemas con tu facturación, actualízalos ahora',
            icon: () => <Stamp size={30} color="var(--primary-1)" />,
            expirationDate: 0,
        },
    ]
    const [noticeCards, setNoticeCards] = useState<NoticeCard[]>([])

    const { team } = useSelector((state: any) => state.team)


    // More notice cards can be added here
    const expiresAt = team?.facturapi?.certificate?.expires_at || team?.facturapi?.legal?.certificate?.expires_at
    const needsCsdNotice = expiresAt ? checkCsdExpiration(expiresAt) : null

    useEffect(() => {
        if (!expiresAt) setNoticeCards([])

        if (needsCsdNotice && !needsCsdNotice.expired) {
            let csdToExpireCard = cardCatalog.find((card) => card.id === 'csdToExpire')!
            csdToExpireCard.expirationDate = needsCsdNotice.expirationDate
            setNoticeCards((prev) => {
                if (!prev.some(card => card.id === csdToExpireCard.id)) {
                    return [...prev, csdToExpireCard]
                }
                return prev
            })
        } else if (needsCsdNotice && needsCsdNotice.expired) {
            let csdExpiredCard = cardCatalog.find((card) => card.id === 'csdExpired')!
            csdExpiredCard.expirationDate = needsCsdNotice.expirationDate
            setNoticeCards((prev) => {
                if (!prev.some(card => card.id === csdExpiredCard.id)) {
                    return [...prev, csdExpiredCard]
                }
                return prev
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        noticeCards,
    }
}

export function useMissingOnboardingStepsCards() {
    // const [missingOnboardingSteps, setMissingOnboardingSteps] = useState<string[]>([]) // TODO: change card type
    const missingOnboardingSteps: any[] = [] // TODO: change card type

    return { missingOnboardingSteps }
}
